@import "../../utils/size.scss";
.heading {
  margin-top: -0.8rem;
  padding-left: 5px;
  position: fixed;
  width: 100%;
  background-color: #fafafa;
  z-index: 5;
  .layout {
    display: flex;
    justify-content: space-between;
  }
  .mobileSliders {
    @media (min-width: $breakpoint-medium) {
      display: none;
    }
  }
}
.dailyRevenue {
  margin-top: 7rem;
  .inputContainer {
    @media (max-width: $breakpoint-small) {
      margin: 10px;
    }
    padding: 10px;
  }
  .dataDisplay {
    @media (max-width: $breakpoint-small) {
      display: none;
    }
  }
  .webSliders {
    @media (max-width: $breakpoint-medium) {
      display: none;
    }
  }
  .mobileSliderRow {
    @media (max-width: $breakpoint-medium) {
      margin-top: 0px !important;
    }
  }

  .mobileDisplayWrapper {
    @media (max-width: $breakpoint-small) {
      margin: 10px;
    }
    .mobileDisplay {
      @media (min-width: $breakpoint-small) {
        display: none;
      }
      @media (max-width: $breakpoint-small) {
        display: content;
      }
    }
  }
}

.loadingSpinner{
  margin-right: 0.5rem;
}

.MuiButton-contained.Mui-disabled.disabledPrimary{
  color:#3f51b5;  
}