@import "../../utils/size.scss";
@import "../../utils/colors.scss";

.heading {
  margin-top: -0.8rem;
  padding-left: 5px;
  width: 100%;
  color: $primaryColor;
  z-index: 5;
  .layout {
    display: flex;
    justify-content: flex-start;
  }
}

.cashRegister {
  .inputContainer {
    margin: 1.5rem 0.5rem;
    padding: 1.5rem;
  }
  .dataDisplay {
    @media (max-width: $breakpoint-small) {
      display: none;
    }
  }

  .mobileDisplayWrapper {
    @media (max-width: $breakpoint-small) {
      margin: 10px;
    }

    .mobileDisplay {
      @media (min-width: $breakpoint-small) {
        display: none;
      }
      @media (max-width: $breakpoint-small) {
        display: content;
      }
    }
  }
}

.loadingSpinner{
  margin-right: 0.5rem;
}
