.mobileDisplayWrapper {
  .elementWrapper {
    width: 100%;
    .element {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      padding: 0px 12px;
    }
  }
}
