@import "../../utils/colors.scss";
@import "../../utils/size.scss";

.headerWrapper {
  .appBar {
    z-index: 10;
    // background-color: #5082bc;
    width: calc(100% - #{$drawerWidthCollapse});
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
  }
  .appBarShift {
    width: calc(100% - #{$drawerWidthExpand});
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: $drawerWidthExpand;
    @media (max-width: $breakpoint-small) {
      width: 100%;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
    .menuButton {
      margin-right: 10px;
    }
    .menuRow {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .hide {
      display: none;
    }
    .headerActions {
      display: flex;
    }
  }
}
