@import "../../utils/size.scss";

.root {
  display: flex;

  .drawer {
    width: 240px;
    flex-shrink: 0;
    white-space: "nowrap";
  }

  .drawerOpen {
    width: 240px;
    transition: width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }

  .drawerClose {
    width: 58px;
    overflow-x: hidden;
    transition: width 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;

    @media (max-width: $breakpoint-small) {
      width: 0px;
    }
  }
  
  .toolbar {
    display: flex;
    height: 70px;
    align-items: center;
    justify-content: center;
  }

  .show {
    opacity: 1;
    animation-name: fadeInOpacity;
    animation-iteration-count: 1;
    animation-timing-function: ease-in;
    animation-duration: 250ms;
  }

  @keyframes fadeInOpacity {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  .hide {
    opacity: 0;
    display: none;
  }
}
