@import "utils/size.scss";

.sideNavigation {
  .root {
    display: flex;
    .content {
      flex-grow: 1;
      padding: 12px 24px;
      width: calc(100vw - 240px);
      @media (max-width: $breakpoint-small) {
        padding: 12px 0px;
      }
    }
    .backdrop {
      z-index: 1202;
      color: white;
    }
  }
}
