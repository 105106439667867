@import "../../utils/size.scss";
@import "../../utils/colors.scss";

.heading {
  margin-top: -0.8rem;
  padding-left: 5px;
  width: 100%;
  color: $primaryColor;
  z-index: 5;

  .layout {
    display: flex;
    justify-content: flex-start;
  }
}

.codaUtils {
  $base-color: hsl(0, 0%, 80%);

  .dropzone {
    margin: 1.5rem 0.5rem;
    padding: 1.5rem;
    text-align: center;
    cursor: pointer;
    padding: 20px;
    border: 2px #307fec dashed;
    background-color: #f4f9fc;

    &:focus {
      outline: none;
    }

    .card {
      // width: 385px;
      // height: 76px;
      background-color: var(--surface-50);

      .pi {
        font-size: 3rem;
        padding-top: 1rem;
      }
    }

    &.dropActive // &:hover

      {
      // box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 20%), 0 1px 1px 0 rgba(0, 0, 0, 14%),
      //   0 1px 3px 0 rgba(0, 0, 0, 12%); // Copied from p-shadow-1 styling

      transform: translateY(2px);
      color: var(--primary-color);
    }

    :deep(.p-card-content) {
      padding: 0;
    }

    .content {
      width: 385px;
      height: 18px;
    }

    p {
      padding: 0;
      margin: 0;
      line-height: 1.4rem;
    }

    //   &.drop-active {
    //     // border-style: solid;
    //     border-width: 4px;
    //     // border-color: darken($color: $base-color, $amount: 10);
    //     // background-color: lighten($color: $base-color, $amount: 10);
    //   }
  }
}