.revenueTable {
  background-color: #ebebeb;
  color: white;
}

tr.totalRow {
  background-color: #f5f5f5;
  // border: solid 2px #888 !important;

  th, td {
    font-weight: bold;
  }
}
