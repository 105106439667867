@import "../../utils/size.scss";
@import "../../utils/colors.scss";

.heading {
  margin-top: -0.8rem;
  padding-left: 5px;
  width: 100%;
  color: $primaryColor;
  z-index: 5;
  .layout {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
  }
  .mobileSliders {
    @media (min-width: $breakpoint-medium) {
      display: none;
    }
  }
}

.dailyRevenue {
  .inputContainer {
    margin: 1.5rem 0.5rem;
    padding: 1.5rem;
  }
  .dataDisplay {
    @media (max-width: $breakpoint-small) {
      display: none;
    }
  }
  .webSliders {
    @media (max-width: $breakpoint-medium) {
      display: none;
    }
  }
  .mobileSliderRow {
    @media (max-width: $breakpoint-medium) {
      margin-top: 0px !important;
    }
  }

  .mobileDisplayWrapper {
    @media (max-width: $breakpoint-small) {
      margin: 10px;
    }
    .mobileDisplay {
      @media (min-width: $breakpoint-small) {
        display: none;
      }
      @media (max-width: $breakpoint-small) {
        display: content;
      }
    }
  }
}

.loadingSpinner{
  margin-right: 0.5rem;
}

.MuiButton-contained.Mui-disabled.disabledPrimary{
  color:#3f51b5;  
}