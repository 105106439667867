@import "../../utils/size.scss";
@import "../../utils/colors.scss";

.companyDetails {
  .heading {
    margin-top: -0.8rem;
    padding-left: 5px;
    width: 100%;
    color: $primaryColor;
    z-index: 5;
  }

  .tableContainer{
    margin: 1.5rem 0.5rem;
    padding: 1.5rem;
  }  
  
  .modal {
    width: 400px;
    border: 2px solid #000;
    padding: 16px 32px 24px;
    position: absolute;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
    background-color: #fff;
  }

  @media (max-width: $breakpoint-small) {
    padding: 0px 20px;
  }

  a{
    text-decoration: none;
  } 

  .loadingSpinner{
    margin-right: 0.5rem;
  }
}