@import "../../utils/size.scss";

.selections {
  display: flex;
  .selectionsForm {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    align-items: center;
    .title {
      color: white;
    }
    .select {
      color: white;
    }
    .companyField {
      width: 8.5rem;
      text-align: center;
      margin-bottom: 0;
    }
    @media (max-width: $breakpoint-small) {
      justify-content: space-evenly;
    }
  }
}
